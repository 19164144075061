// * Why using TextStyle alter for Heading Components => because this can config responsive style
// * If want to use Heading components => when don't need to use responsive style
// * Note: Config small to big [sm, md, lg, xl, 2xl]
export const TextStyle = {
  h1: {
    fontSize: ['18px', "18px", '18px', '20px', '24px'],
    fontWeight: '600',
    color: "#333333",
  },
  h2: {
    fontSize: ['16px', '16px', '16px', '18px', '20px'],
    fontWeight: '600',
    color: "#333333",
  },
  h3: {
    fontSize: ['18px'],
    fontWeight: '600',
    color: "#333333",
  },
  h4: {
    fontSize: ['16px'],
    fontWeight: '600',
    color: "#333333",
  },
  h5: {
    fontSize: ['14px'],
    fontWeight: '600',
    color: "#333333",
  },
  paragraph: {
    fontSize: ['14px'],
    fontWeight: '400',
    color: "#333333",
  },
  baseStyle: {
    span: {
      display: 'inline-block'
    },
  },
}