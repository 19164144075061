export const AlertDefined = {
    variants: {
        // define own toast variant
        default: {
            container: {
                bg: "black.200",
                borderRadius: "8px",
                padding: "10px 12px",
                display: "flex",
                alignItems: 'center',
                width: "calc(100% + 24px)",
                maxWidth: 'unset'
            },
            title: {
                fontSize: 14,
                fontWeight: 500,
                color: "white",
            },
            description: {
                fontSize: 14,
                fontWeight: 500,
                color: "white",
            },
            icon: {
                height: "24px",
                width: "24px",
                display: "flex",
                marginRight: "4px"
            }
        },
        error: {
            container: {
                bg: "black.200",
                borderRadius: "8px",
                padding: "10px 12px",
                display: "flex",
                alignItems: 'center',
                width: "calc(100% + 24px)",
                maxWidth: 'unset'
            },
            title: {
                fontSize: 14,
                fontWeight: 500,
                color: "white",
            },
            description: {
                fontSize: 14,
                fontWeight: 500,
                color: "white",
            },
            icon: {
                display: "none",
                width: 0,
                height: 0,
            }
        },
        success: {
            container: {
                bg: "green.200",
                borderRadius: "8px",
                padding: "10px 12px",
                display: "flex",
                alignItems: 'center',
                width: "calc(100% + 24px)",
                maxWidth: 'unset'
            },
            title: {
                fontSize: 14,
                fontWeight: 500,
                color: "white",
            },
            description: {
                fontSize: 14,
                fontWeight: 500,
                color: "white",
            },
            icon: {
                height: "24px",
                width: "24px",
                display: "flex",
                marginRight: "4px"
            }

        }
    }
}
