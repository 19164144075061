import { extendTheme } from "@chakra-ui/react";

/**
 * All configs will import in this folder
 */
import { Breakpoints, ButtonDefined, CheckboxDefined, Colors, MenuDefined, Spacing, SpinnerDefined, TextStyle, TooltipDefined, PopoverDefined, InputDefined, SkeletonDefined, AlertDefined, TabDefined, ModalDefined, TagDefined, TextAreaDefined } from "./config";

const primaryColorText = 'gray.900';
const primaryColorBg = 'rgba(32, 34, 35, 0)';


const disabledStyles = {
  pointerEvents: "none",
  cursor: "not-allowed"
};

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: primaryColorBg,
        color: primaryColorText,
      },
      heading: {
        color: "#333333",
      },
      svg: {
        display: 'unset'
      }
    }
  },
  fonts: {
    heading: 'Inter',
    body: 'Inter'
  },
  space: Spacing,
  colors: Colors,
  textStyles: TextStyle,
  breakpoints: Breakpoints,
  components: {
    Button: {
      defaultProps: {
        size: 'm',
        variant: 'outline-default'
      },
      sizes: {
        s: {
          minH: "24px",
          maxH: "24px",
          minW: "24px",
          fontSize: "12px",
          fontWeight: '600',
          px: "4px",
          py: "10px",
          borderRadius: "8px",
          lineHeight: "20px",
          _focus: {
            outline: 'none',
          },
          _disabled: disabledStyles,
        },
        m: {
          minH: "32px",
          maxH: "32px",
          minW: "32px",
          fontSize: "14px",
          fontWeight: '600',
          py: "8px",
          px: "10px",
          borderRadius: "8px",
          lineHeight: "20px",
          _focus: {
            outline: 'none',
          },
          _disabled: disabledStyles,
        },
        l: {
          minH: "40px",
          maxH: "40px",
          minW: "40px",

          fontSize: "14px",
          fontWeight: '600',
          px: "14px",
          py: "10px",
          borderRadius: "8px",
          lineHeight: "20px",
          _focus: {
            outline: 'none',
          },
          _disabled: disabledStyles,
        }
      },
      variants: ButtonDefined
    },
    Checkbox: CheckboxDefined,
    Tooltip: TooltipDefined,
    Spinner: SpinnerDefined,
    Menu: MenuDefined,
    Popover: PopoverDefined,
    Input: InputDefined,
    Skeleton: SkeletonDefined,
    Alert: AlertDefined,
    Modal: ModalDefined,
    Tag: TagDefined,
    Tabs: TabDefined,
    Textarea: TextAreaDefined
  }
})