export const Colors = {
  gray: {
    900: "#4b5563",
    400: '#212121'
  },
  red: {
    500: "#e33",
    400: '#fc625d'
  },
  blue: {
    200: "#2979FF"
  },
  black: {
    200: "#111827"
  },
  green: {
    200: "#16A249"
  }
}