import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(tabsAnatomy.keys)

const sizes = {
    md: definePartsStyle({
        tab: {
            fontSize: '14px',
            pb: 5,
            pt: 0,
            mr: 4,
            mt: 8,
            fontWeight: 600,
            lineHeight: "20px"
        },
    }),
};

// define the base component styles
const baseStyle = definePartsStyle({
    // define the part you're going to style
    tab: {
        color: '#4B5563',
        _focus: {
            boxShadow: "none",
            outline: "none",
        },
        _selected: {
            color: "#2463EB",
            borderColor: "#2463EB"
        }
    },
    tablist: {
        px: 8,
    }
})

// export the component theme
export const TabDefined = defineMultiStyleConfig({ baseStyle, sizes })