import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const base = defineStyle({
    fontWeight: 400,
    textAlign: "left",
    border: '1px solid #D1D5DB',
    color: "#212121",
    borderRadius: "4px",
    fontSize: "14px",
    _focus: {
        boxShadow: '0px 0px 0px 4px #DCEBFE',
        border: '1px solid #2979ff',
    },
    _disabled: {
        borderRadius: "4px",
        border: "1px solid  #E5E7EB",
        background: "#F3F4F6",
        color: '#9CA3B0'
    },
    _invalid: {
        boxShadow: '0px 0px 0px 4px rgba(220,40,40, 0.1)',
        border: '1px solid #DC2828',
    }
})

export const TextAreaDefined = defineStyleConfig({ variants: { base } })