import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import { defineStyle } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(parts.keys)


const baseStyle = definePartsStyle({
    // define the part you're going to style
    header: {
        fontSize: '16px',
        padding: "8px 6px 8px 16px",
        borderBottom: '1px solid #E5E7EB',
        fontWeight: "500",
        lineHeight: '24px',
    },
    body: {
        p: 8,
    },
    footer: {
        p: "12px 16px",
        borderTop: '1px solid #E5E7EB',
    },
    closeButton: {
        fontSize: '10px',
        p: '0',
        h: '40px',
        w: '40px',
        borderRadius: 'md',
        mt: "6px"
    },
    overlay: {
        bg: 'blackAlpha.500', //change the background
        overflow: 'hidden'
    },
    dialog: {
        borderRadius: '16px',
        bg: "white",
    },
});

const sm = defineStyle({
    fontSize: 'sm',
    w: "100%",
    maxW: 480
});

const md = defineStyle({
    fontSize: 'sm',
    w: "100%",
    maxW: 600
});

const md2 = defineStyle({
    fontSize: 'lg',
    w: "100%",
    maxW: 700
});


const lg = defineStyle({
    fontSize: 'lg',
    w: "100%",
    maxW: 954
});

const sizes = {
    sm: definePartsStyle({
        header: sm,
        dialog: sm,
        footer: sm
    }),
    md: definePartsStyle({
        header: {
            ...md,
            ...defineStyle({
                p: "12px 16px"
            })
        },
        footer: {
            ...md,
            ...defineStyle({
                p: "12px 16px"
            })
        },
        body: md,
        dialog: md,
    }),
    md2: definePartsStyle({
        header: {
            ...md2,
            ...defineStyle({
                p: "12px 16px"
            })
        },
        footer: {
            ...md2,
            ...defineStyle({
                p: "12px 16px"
            })
        },
        body: md2,
        dialog: md2,
    }),
    lg: definePartsStyle({
        header: {
            ...lg,
            ...defineStyle({
                p: "8px 16px 8px 16px"
            })
        },
        footer: {
            ...lg,
            ...defineStyle({
                p: "10px 16px 10px 16px"
            })
        },
        body: {
            ...lg,
            ...defineStyle({
                fontSize: "sm"
            })
        },
        dialog: lg,
    }),
}

const config = {
    body: { bg: `#F3F4F6`, }
}

const about = {
    footer: {
        justifyContent: "flex-start"
    }
}

const rating = {
    header: {
        borderBottom: "unset"
    },

    footer: {
        borderTop: 'unset',
        justifyContent: "flex-start"
    }
}
export const ModalDefined = defineMultiStyleConfig({
    baseStyle,
    sizes,
    variants: {
        config,
        about,
        rating
    }
})
