// ! Tone > type button
const defined = {
    default: {
        default: {
            color: '#4b5563',
            bg: 'rgba(255, 255, 255, 1)',
            border: '1px solid #9ca3b0',
            transition: "all 0.3s",
            boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.12)",
            _hover: {
                bg: "rgba(249, 250, 251, 1)",
                boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.12)"
            },
            _active: {
                boxShadow: "0 2px #00000014 inset",
                bg: 'rgba(243, 244, 246, 1)'
            },
            _disabled: {
                bg: 'rgba(255, 255, 255, 1)',
                border: '1px solid rgba(209, 213, 219, 1)',
                color: 'rgba(209, 213, 219, 1)'
            }
        },
        primary: {
            color: 'rgba(255, 255, 255, 1)',
            bg: 'rgba(36, 99, 235, 1)',
            boxShadow: "0px -2px 0px 0px #00000029 inset",
            transition: "all 0.3s",
            _hover: {
                bg: "rgba(29, 79, 215, 1)",
                boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.12)"
            },
            _active: {
                boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.08) inset",
                bg: '#1e3fae'
            },
            _disabled: {
                bg: 'rgba(243, 244, 246, 1)',
                border: '1px solid transparent',
                color: 'rgba(209, 213, 219, 1)'
            }
        },
        tertiary: {
            color: "rgba(75, 85, 99, 1)",
            _hover: {
                bg: 'rgba(229, 231, 235, 0.5)'
            },
            _active: {
                bg: 'rgba(229, 231, 235, 0.8)'
            },
            _disabled: {
                color: 'rgba(209, 213, 219, 1)'
            }
        },
        plain: {
            color: "rgba(36, 99, 235, 1)",
            p: 0,
            maxH: "unset",
            minW: "unset",
            minH: "unset",
            lineHeight: "14px",
            fontWeight: 400,
            _hover: {
                color: "rgba(29, 79, 215, 1)",
                textDecoration: "underline",
            },
            _active: {
                color: "rgba(30, 63, 174, 1)"
            },
            _disabled: {
                color: "rgba(209, 213, 219, 1)"
            }
        }
    },
    critical: {
        default: {
            color: 'rgba(186, 28, 28, 1)',
            bg: 'rgba(255, 255, 255, 1)',
            border: '1px solid rgba(156, 163, 176, 1)',
            transition: "all 0.3s",
            _hover: {
                bg: "rgba(249, 250, 251, 1)",
                boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.12)"
            },
            _active: {
                boxShadow: "0px 3px 0px 0px rgba(0, 0, 0, 0.08) inset",
                bg: 'rgba(243, 244, 246, 1)'
            },
            _disabled: {
                bg: 'rgba(255, 255, 255, 1)',
                border: '1px solid rgba(209, 213, 219, 1)',
                color: 'rgba(209, 213, 219, 1)'
            }
        },
        primary: {
            color: 'rgba(255, 255, 255, 1)',
            bg: 'rgba(220, 40, 40, 1)',
            boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.12)",

            transition: "all 0.3s",
            _hover: {
                bg: "rgba(186, 28, 28, 1)",
                boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.12)"
            },
            _active: {
                boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.16) inset",
                bg: 'rgba(152, 27, 27, 1)'
            },
            _disabled: {
                bg: 'rgba(243, 244, 246, 1)',
                border: '1px solid transparent',
                color: 'rgba(209, 213, 219, 1)'
            }
        },
        tertiary: {
            color: "rgba(220, 40, 40, 1)",
            _hover: {
                bg: 'rgba(254, 200, 200, 0.2)'
            },
            _active: {
                bg: 'rgba(254, 200, 200, 0.3)'
            },
            _disabled: {
                color: 'rgba(220, 40, 40, 1)'
            }
        },
        plain: {
            maxH: "unset",
            minW: "unset",
            minH: "unset",
            color: "rgba(220, 40, 40, 1)",
            p: 0,
            lineHeight: "14px",
            fontWeight: 400,
            _hover: {
                color: "rgba(186, 28, 28, 1)",
                textDecoration: "underline",
            },
            _active: {
                color: "rgba(186, 28, 28, 1)",
                textDecoration: "underline",
            },
            _disabled: {
                color: "rgba(209, 213, 219, 1)"
            }
        }
    }
}

const disabledStyles = {
    pointerEvents: "none",
    cursor: "not-allowed",
};

const WheelButton = {};

// ! Định nghĩa các button của hệ thống
const WHEEL_BUTTON_COMBINE = [
    "default-default",
    "default-primary",
    "default-tertiary",
    "default-plain",
    "critical-default",
    "critical-primary",
    "critical-tertiary",
    "critical-plain",
];

// ! tự động generate ra có định nghĩa style của button theo WHEEL_BUTTON_COMBINE
WHEEL_BUTTON_COMBINE.forEach((button) => {
    let splitBtn = button.split('-');

    const toneBtn = splitBtn[0];
    const typeBtn = splitBtn[1];

    WheelButton[button] = {
        ...defined[toneBtn][typeBtn],
        _disable: disabledStyles,
        // _focus: {
        //     boxShadow: "0px 0px 0px 4px rgba(220, 235, 254, 1)"
        // },
    }
});

export const ButtonDefined = {
    ...WheelButton,
    'outline-default': {
        color: "gray.900",
        bg: "white",
        border: '1px solid #D1D5DB',
        _hover: {
            bg: "#ececec",
        },
    },
    "solid-default": {
        color: "#3d4f6e",
        bg: "#eeeeee",
        _hover: {
            opacity: "0.7",
        },
    },
    "solid-default[100]": {
        color: "#ffffff",
        bg: "#414552",
        _hover: {
            opacity: "0.7",
        },
    },
    'solid-primary': {
        color: "white",
        bg: "#2463eb",
        _hover: {
            opacity: "0.7",
        },
    },
    "solid-primary[100]": {
        // * có prefix 100 thì sẽ đậm hơn
        color: "white",
        bg: "#2463EB",
        _hover: {
            opacity: "0.7",
        },
    },
    'solid-danger': {
        color: "white",
        bg: "red.400",
        _hover: {
            opacity: "0.7",
        },
    },
    'ghost-default': {
        color: "gray.900",
        _hover: {
            bg: "#E5E7EB4D"
        }
    },
    'ghost-danger': {
        color: "red.500",
        _hover: {
            bg: "hsla(0,96%,89%, .2)"
        }
    },
    'selection': {
        fontWeight: 400,
        textAlign: "left",
        border: '1px solid #D1D5DB',
        color: "#212121",
        _hover: {
            border: '1px solid #2979ff',
        },
        _active: {
            boxShadow: '0px 0px 0px 4px #DCEBFE',
            border: '1px solid #2979ff',
        }
    },
    'rounded-default': {
        textAlign: "center",
        border: "1px solid #D1D5DB",
        borderRadius: "100px",
        bg: "#ffffff",
        fontWeight: 500,
        _hover: {
            boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.16), 0px 0px 3px 0px rgba(0, 0, 0, 0.12)",
        },
    },
    'metric-tertiary': {
        display: "flex",
        justifyContent: 'flex-start',
        fontSize: "16px",
        fontWeight: 600,
        color: "#111827",
        _hover: {
            bg: 'rgba(229, 231, 235, 0.5)'
        },
        _active: {
            bg: 'rgba(229, 231, 235, 0.8)'
        },
        _disabled: {
            color: 'rgba(209, 213, 219, 1)'
        }
    }
}