// && Document: https://chakra-ui.com/docs/components/popover/theming

import { popoverAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
    popper: {
        p: 0,
        zIndex: 23,
        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.12), 0px 4px 20px rgba(0, 0, 0, 0.16)",
        borderRadius: "8px",
        border: 'none',
        borderColor: "transparent"
    },
    body: {
        p: 0,
    },
});

const selectionMulti = {
    popper: {
        p: 0,
        zIndex: 9999,
        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.12), 0px 4px 20px rgba(0, 0, 0, 0.16)",
        borderRadius: "8px",
        border: 'none',
        borderColor: "transparent",
    },
    body: {
        p: 0,
        maxH: 240,
        overflow: "auto",
        overflowY: "hidden"
    },
}


export const PopoverDefined = defineMultiStyleConfig({ baseStyle, variants: { selectionMulti }, })