import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const md = defineStyle({
    width: 8,
    height: 8,
});

const sm = defineStyle({
    width: "20px",
    height: "20px",
});

const app = defineStyle({
    borderColor: "blue.200"
});

const white = defineStyle({
    borderColor: "white"
});

export const SpinnerDefined = defineStyleConfig({
    sizes: { md, sm },
    variants: { app, white }
})