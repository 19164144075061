import { defineStyle, defineStyleConfig, cssVar } from '@chakra-ui/react'

const $startColor = cssVar('skeleton-start-color')
const $endColor = cssVar('skeleton-end-color')

const xl = defineStyle({
    h: 9,
});

const defaultStyle = defineStyle({
    [$startColor.variable]: 'colors.gray.100', //changing startColor to red.100
    [$endColor.variable]: 'colors.gray.300', // changing endColor to red.400
})


export const SkeletonDefined = defineStyleConfig({
    sizes: { xl },
    variants: { defaultStyle },
    defaultProps: {
        size: "xl",
        variant: "defaultStyle"
    }
})