import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(inputAnatomy.keys)

const l = defineStyle({
    fontSize: '14px',
    px: '12px',
    py: '8px',
    h: '40px',
});

const m = defineStyle({
    fontSize: '14px',
    px: '12px',
    py: '8px',
    h: '32px',
});

const sizes = {
    l: definePartsStyle({
        field: l,
    }),
    m: definePartsStyle({
        field: m,
    }),
}

const baseStyle = definePartsStyle({
    field: {
        fontWeight: 400,
        textAlign: "left",
        border: '1px solid rgba(209, 213, 219, 1)',
        color: "#212121",
        borderRadius: "8px",
        fontSize: "14px",
        _hover: {
            border: '1px solid rgba(156, 163, 176, 1)'
        },
        _focus: {
            boxShadow: '0px 0px 0px 4px #DCEBFE',
            border: '1px solid #2979ff',
        },
        _disabled: {
            border: "1px solid  rgba(229, 231, 235, 1)",
            background: "rgba(243, 244, 246, 1)",
            color: 'rgba(75, 85, 99, 1)',
            _placeholder: {
                color: 'rgba(75, 85, 99, 1)',
            },
            _hover: {
                border: '1px solid  rgba(229, 231, 235, 1)'
            },
        },
        _invalid: {
            boxShadow: '0px 0px 0px 4px rgba(220,40,40, 0.1)',
            border: '1px solid #DC2828',
        }
    },
});

export const InputDefined = defineMultiStyleConfig({
    defaultProps: {
        size: 'l',
        variant: ""
    },
    baseStyle,
    sizes
})