
// ! Default unit is 2;
const baseSpacing = 2;

//* mt-1: 1 * 2 = 2;
//* mt-2: 2 * 2 = 4;
export const Spacing = Array.from({ length: 50 }, (_, i) => {
    if (i > 0) {
        return `${baseSpacing * (i)}px`
    } else {
        return `${i}px`
    }
})