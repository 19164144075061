import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const sizes = {
    md: definePartsStyle({
        label: {
            fontFamily: 'Inter',
            color: "gray.400",
            fontSize: '14px',
            lineHeight: '20px'
        },
        control: {
            borderRadius: 4, // change the border radius of the control
            width: '16px',
            height: '16px',
            borderColor: "gray.900",
            bg: "white",
            borderWidth: 1,
            _disabled: {
                borderColor: "gray.900",
                bg: "white",
                opacity: 0.7,
            },
            _checked: {
                bg: "blue.200",
                borderColor: "blue.200",
                _disabled: {
                    opacity: 0.7,
                    bg: "blue.200",
                    color: 'white',
                    borderColor: "blue.200",
                },
                _hover: {
                    borderColor: "blue.200",
                    bg: "blue.200",
                },
            },
            _focusVisible: { shadow: 'outline' },
            _focus: { shadow: 'none' }
        },
        container: {
            py: 4,
            mb: 0,
            width: '100%',
            alignItems: "start",
            borderRadius: "8px"
        },
        icon: {
            fontSize: "8px"
        }
    })
}

const variants = {
    coupon: {
        container: defineStyle({
            display: "flex",
            alignItems: "center",
            width: "100%",
            borderRadius: 0,
            ml: 4,
            px: 4,
            py: 0,
            _hover: {
                bg: "rgba(229, 231, 235, 0.5)",
                borderRadius: 4
            },
            mb: 1
        }),
        label: defineStyle({
            flex: "1",
        }),
    }
}

const CheckboxDefined = defineMultiStyleConfig({ sizes, variants, })

export {
    CheckboxDefined
}