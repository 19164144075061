import { tagAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(tagAnatomy.keys)

const baseStyle = definePartsStyle({
    // define the part you're going to style
    container: {
        px: 4,
        bg: "rgba(229, 231, 235, 0.80)",
        borderRadius: "4px",
        h: "24px",
        mr: 4,
        mt: 4
    },
    label: {
        fontWeight: 400,
        fontSize: "14px",
        color: "#4B5563",

    },
    closeButton: {
        fontSize: "14px",
        color: "#4B5563",
    }
})

export const TagDefined = defineMultiStyleConfig({ baseStyle })