import { cssVar, defineStyleConfig } from '@chakra-ui/react'

const $arrowBg = cssVar("popper-arrow-bg");
const $arrowShadow = cssVar("popper-arrow-shadow-color");

// define the base component styles
const baseStyle = {
    borderRadius: '4px', // add a border radius
    bg: 'black',
    color: "white",
    fontSize: '12px',
    p: 6,
    [$arrowBg.variable]: "black", // add Arrow Color
    maxW: "250px"
}

const variants = {
    light: {
        bg: 'white',
        [$arrowBg.variable]: "white", // add Arrow Color
        [$arrowShadow.variable]: "#e4e6ec", // add Arrow Color
    },
    rfm: {
        bg: 'white',
        boxShadow: "0px 12px 24px 0px rgba(0, 0, 0, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.24)", // add Arrow Color
        borderRadius: 8
    }
}

// export the component theme
export const TooltipDefined = defineStyleConfig({ baseStyle, variants })